<template>
  <b-modal
    id="accrual-madal"
    ref="accrual-madal"
    no-close-on-backdrop
    hide-footer
    title="บันทึกรายการลูกค้าค้าง"
    @show="resetModal"
    @hidden="resetModal"
  >

    <div class="p-3">

      <div class="mb-2">
        <span class="small">วันที่: {{date}} โดย: {{by}}</span>
        <div v-if="description" class="p-1 alert alert-info mb-0">
          {{description}}
        </div>
      </div>

      <div class="mb-2" v-for="(item, key) in items" :key="key">
        <span v-if="item.status" class="badge badge-pill badge-success mr-2">จ่ายแล้ว</span>
        <span class="small">เวลา: {{item.date}} โดย: {{item.by}}</span>
        <div v-if="item.message || item.image" class="p-1 alert alert-secondary mb-0">
          <div>{{item.message}}</div>
          <div class="text-center mt-3 mb-2" v-if="item.image">
            <a :href="item.image" target="_blank">
              <div style="width: 90%; margin: 0 auto; padding: 5px; border: 1px solid #0c5460; display: block; background: #FFF; border-radius: 5px;">
                <img :src="item.image" style="max-width: 100%;">
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'AccrualModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {
        description: null
      }
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      message: null,
      isPaid: false,
      file: null
    }
  },
  computed: {
    by() {
      return this.data?.logs.by
    },
    date() {
      return this.data?.logs.date
    },
    description() {
      return this.data?.description
    },
    items() {
      return this.data?.logs?.items || []
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.message = null
      this.needReload = false
      this.isProcess = false
      this.isPaid = null
    },
    showModal() {
      this.$refs['accrual-madal'].show()
    },
    hideModal() {
      this.$refs['accrual-madal'].hide()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#accrual-madal {

  input[type=text] {
    color: blue;
  }

  .modal-dialog {
    .modal-body {
      padding: 0;
    }
  }
}
</style>
